var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"Name","tooltip":"Enter Name ","label":"Provider","placeholder":"Enter Name","valuex":_vm.appData.name,"validations":"required"},on:{"updatedata":function (val) { return (_vm.appData.name = val); }}})],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(" Status")]),_c('inputvselect',{attrs:{"name":"Status","label":function (reldata) { return reldata.name; },"tooltip":"Status","valuex":_vm.appData.status,"keys":"key","options":[
                  {
                    id: 0,
                    name: 'Disabled',
                  },
                  {
                    id: 1,
                    name: 'Active',
                  } ],"id":"status"},on:{"updatedata":function (val) { return (_vm.appData.status = val.id); }}})],1)],1),_c('div',{staticClass:"d-flex float-right mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Back")])],1),(_vm.edittype == 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","to":{
                name: _vm.GENAPP_APP_STORE_MODULE_NAME + '-view',
                params: { id: _vm.iddata },
              }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EyeIcon"}}),_vm._v(" View ")],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary                            ","type":"submit"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"Edit3Icon"}}),_vm._v(" Save ")],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }